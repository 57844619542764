var swiper = new Swiper('.swiper-aos', {
  effect:'fade',
  autoplay:
  {
    delay: 2000,
  },
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next-aos',
    prevEl: '.swiper-button-prev-aos',
  },
  pagination: {
    el: '.swiper-pagination-aos',
  },
  on: {
    slideChangeTransitionStart: function () {
      // $('.card').children('.cardBox').children('.cardBoxImg').hide(0);
      // $('.card').children('.cardBox').children('.cardBoxImg').removeClass('aos-init').removeClass('aos-animate');
    },
    slideChangeTransitionEnd: function () {
      $('.card').children('.cardBox').children('.cardBoxImg').show(0);
      AOS.init();
    },
  }


});

AOS.init();
